.AnimationClickLogo{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    z-index: 6;

    position: relative;
}
.hide_logo_animation_initial{
    height: 0;
    overflow: hidden;
    z-index: 0;
}

.AnimationClickLogo .anim_logo_line_color{
    width: 25%;
    height: 0;
    background-color: #ededed;
    position: absolute;
    bottom: 0;
}
.AnimationClickLogo .anim_logo_line_1{
    left: 0%;
}
.AnimationClickLogo .anim_logo_line_2{
    left: 25%;
}
.AnimationClickLogo .anim_logo_line_3{
    left: 50%;
}
.AnimationClickLogo .anim_logo_line_4{
    left: 75%;
}
.AnimationClickLogo .anim_logo_show_animation {    
    animation: anim_logo_show_animation 1s linear;
    animation-fill-mode: forwards;  
}
@keyframes anim_logo_show_animation {
    0%, 20% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}
.AnimationClickLogo .anim_logo_hide_animation {    
    animation: anim_logo_hide_animation 1s linear;
    animation-fill-mode: forwards;  

    bottom: unset;
    top: 0;
}
@keyframes anim_logo_hide_animation {
    0% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}

.AnimationClickLogo .anim_logo_line_color:nth-child(1){
    border-left: none;
}
.AnimationClickLogo .anim_logo_line_3{
    display: flex;
    align-items: flex-end;
}

.AnimationClickLogo .anim_logo_color_1{
    border-left: 1px solid rgba(77, 77, 77, .3);
}


.AnimationClickLogo .anim_logo_color_2{
    border-left: 1px solid rgba(77, 77, 77, .3);

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    animation: anim_logo_color_animation 1.5s linear;
    animation-fill-mode: forwards;    
}


.AnimationClickLogo .anim_logo_color_3{
    width: 80px;

    border-left: 1px solid rgba(77, 77, 77, .3);

    overflow: hidden;    
    position: relative;
    
    animation: anim_logo_color_animation 1.5s linear;
    animation-fill-mode: forwards;  
}


.AnimationClickLogo .anim_logo_color_4{  
    border-left: 1px solid rgba(77, 77, 77, .3);
      
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    animation: anim_logo_color_animation 1.5s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_logo_color_animation {
    0%, 20% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}


.AnimationClickLogo .anim_logo_color_3 .anim_logo_show_percentage{
    position: relative;
    right: -10px;
    font-size: 22px;
}
.AnimationClickLogo .anim_logo_color_3 .anim_logo_show_percentage_animation{
    animation: anim_logo_show_percentage 0.8s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_logo_show_percentage {
    0% {
        right: -10px;
    }
    100% {
        right: 60px;
    }
}


.AnimationClickLogo .anim_logo_hide_color_line{    
    animation: anim_logo_hide_color_line 1s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_logo_hide_color_line {
    0%{
        height: 100%;
    }
    100% {
        height: 0%;
    }
}