.Menu{
    width: 100%;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 10;
    z-index: 4;
    transition: 1s;
}
.Menu .contents{
    width: 100%;
    max-width: 100%;
    height: 80px;
    margin: 0% auto;
    position: fixed;
    top: 0;
    z-index: 4;
    transition: 1s;
}
.Menu .div_logo{
    position: absolute;
    top: 10vh;
    left: 7vw;
    z-index: 556;
    transition: 1s;
}
.Menu .div_logo .logotipo{
    width: 40px;
    height: auto;
}

.Menu .div_line_menu {
    position: absolute;
    top: 10vh;
    right: 7vw;
    z-index: 556;

    width: 35px;
    height: 35px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: 1s;
}
.Menu .div_line_menu .line_1_menu, .Menu .div_line_menu .line_2_menu{
    background: #ededed;
    display: block;
    height: 2px;
    width: 35px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all .25s cubic-bezier(.475, .425, 0, .995);
    -o-transition: all .25s cubic-bezier(.475, .425, 0, .995);
    transition: all .25s cubic-bezier(.475, .425, 0, .995);
}
.Menu .div_line_menu .line_1_open {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    
    position: relative;
    top: 6px;

    animation: line_bg_color 1.5s;
    animation-fill-mode: forwards;
}
.Menu .div_line_menu .line_2_open {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #2d3c53;
    
    animation: line_bg_color 1.5s;
    animation-fill-mode: forwards;
}
@keyframes line_bg_color {
    0%{
        background-color: #ededed;
    }
    100% {
        background-color: #2d3c53;
    }
}
.Menu .div_line_menu .line_close {    
    animation: line_close 1.5s;
    animation-fill-mode: forwards;
}
@keyframes line_close {
    0%, 60%{
        background-color: #2d3c53;
    }
    100% {
        background-color: #ededed;
    }
}

.Menu .bg_line_color {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: inherit;
}
.Menu .bg_top {
    top: 0;
    height: 100%;
}
.Menu .bg_bottom {
    bottom: 0;
    height: 100%;
}
.Menu .bg_line_color .line_color{
    width: 25%;
    height: 0%;
    transition: all .6s cubic-bezier(.645, .045, .355, 1);
    overflow: hidden;
    border-left: 1px solid rgba(77, 77, 77, .3);
    position: relative;
}
.Menu .bg_line_color .line_color_4{
    overflow: hidden;
}
.Menu .bg_line_color .line_color_2{
    width: 50%;
}

.Menu .bg_line_color .animation_top{
    animation: animation_top 0.5s;
    animation-fill-mode: forwards;
    background-color: #ededed;
}
@keyframes animation_top {
    0%{
        height: 0;
    }
    100% {
        height: 100%;
    }
}
.Menu .bg_line_color .div_opt_menu {
    width: 140%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    gap: 36px;
    position: relative;
    left: -80px;
    opacity: 0;
}
.Menu .bg_line_color .name_menu_show {
    animation-duration: 1s;
    animation-name: name_menu_show;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}
@keyframes name_menu_show {
    0%{
        left: -80px;
    }
    100%{
        opacity: 1;
        left: 0px;
        padding-left: 40px;
    }
}
.Menu .bg_line_color .name_menu_hide {
    animation-duration: 1s;
    animation-name: name_menu_hide;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}
@keyframes name_menu_hide {
    0% {
        left: 0;
    }

    100% {
        opacity: 1;
        left: -120px;
    }
}
.Menu .bg_line_color .div_opt_menu .name_menu{    
    font-size: 45px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 4px;
    color: #9f9f9f;

    position: relative;
    -webkit-text-stroke: 0px #9f9f9f;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
}
.Menu .bg_line_color .div_opt_menu .menu_active{
    color: #121212;
}
.Menu .bg_line_color .div_opt_menu .name_menu:hover::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    /* left: 14px; */
    height: 100%;
    -webkit-text-stroke: 0vw #777777;

    color: #121212;

    animation-duration: 0.5s;
    animation-name: name_menu_before;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    overflow: hidden; 
}
@keyframes name_menu_before {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

.Menu .bg_line_color .animation_bottom{
    animation: animation_bottom 0.5s;
    animation-fill-mode: forwards;
    background-color: #ededed;
}
@keyframes animation_bottom {
    0%{
        height: 100%;
    }
    100% {
        height: 0%;
    }
}

.Menu .bg_line_color .data_address{
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0vw 1vw 0vw 7vw;
}
.Menu .bg_line_color .data_address .title {
    color: #909090;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.Menu .bg_line_color .data_address .text_address{
    font-size: 18px;
    line-height: 35px;
    margin-left: -1px;
    font-weight: normal;
    margin-bottom: 1em;
    color: #121212;
}

.Menu .bg_line_color .list_rede_social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 14vw;
    padding-left: 5vw;
    transform: none;
    transition: 1s;
}
.Menu .bg_line_color .list_rede_social_animation_open {
    animation: list_rede_social_animation_open 0.5s;
    animation-fill-mode: forwards;
    background-color: #ededed;
}
@keyframes list_rede_social_animation_open {
    0%{
        transform: skewY(10deg);
    }
    100% {
        transform: initial;
    }
}
.Menu .bg_line_color .list_rede_social_animation_close {
    animation: list_rede_social_animation_close 0.5s;
    animation-fill-mode: forwards;
    background-color: #ededed;
}
@keyframes list_rede_social_animation_close {
    0%{
        transform: initial;
    }
    100% {
        transform: skewY(10deg);
    }
}
.Menu .bg_line_color .list_rede_social .rede_social {
    font-size: 20px;
    line-height: 45px;
    color: #121212;
}

@media only screen and (max-width: 850px) {
    .Menu .bg_line_color .line_color{
        overflow: initial;
    }
    .Menu .bg_line_color .line_color_1, .Menu .bg_line_color .line_color_2{
        width: 50%;
    }
    .Menu .bg_line_color .line_color_3, .Menu .bg_line_color .line_color_4{
        width: 0;
    }
    .Menu .bg_line_color .data_address{
        display: none;
    }
    .Menu .bg_line_color .div_opt_menu{
        width: 200%;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        padding-left: 0px !important;
    }
    .Menu .bg_line_color .div_opt_menu .name_menu{
        padding-left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .Menu .div_logo{
        top: 6vw;
        left: 6vw;
    }
    .Menu .div_line_menu{
        top: 6vw;
        right: 6vw;
    }
    .Menu .bg_line_color .div_opt_menu .name_menu{
        font-size: 32px;
        line-height: 40px;
    }
}