.ModelPage_Clients {
    width: 80%;
    height: calc(100% - 300px);
    margin: 0% auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: ModelPage_Clients;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    padding: 150px 0px;
}
@keyframes ModelPage_Clients {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ModelPage_Clients .div_text{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModelPage_Clients .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    width: 100%;
}
.ModelPage_Clients .div_text .show_text .subtitle{
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
}
.ModelPage_Clients .div_text .show_text .title{
    font-weight: 600;
    font-style: normal;
    font-size: 55px;
    line-height: 55px;
    transition-delay: 0s;
}
.ModelPage_Clients .div_text .show_text .text{
    font-weight: 400;
    font-style: normal;
    transition-delay: 0s;
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
}

.ModelPage_Clients .list_logo{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    gap: 16px;
}
.ModelPage_Clients .list_logo .div_img{
    width: 170px;
    height: auto;
}
.ModelPage_Clients .list_logo .div_img .logo{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 760px) {
    .ModelPage_Clients .div_text{
        width: 100%;
        padding: 0;
        margin: 0% auto;
        justify-content: center;
    }
    .ModelPage_Clients .div_text .show_text{
        gap: 10px;
    }
    .ModelPage_Clients .div_text .show_text .title{
        font-size: 46px;
    }
    .ModelPage_Clients .div_text .show_text .text{
        font-size: 14px;
    }
    
    .ModelPage_Clients .list_logo{
        justify-content: center;
    }
    .ModelPage_Clients .list_logo .div_img{
        width: 46%;
        height: auto;
    }
}
@media only screen and (max-width: 510px) {
    .ModelPage_Clients .div_text .show_text .title{
        line-height: 56px;
    }
}