.AnimationInitial{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 6;
}
.hide_animation_initial{
    height: 0;
    overflow: hidden;
    z-index: 4;
}
.AnimationInitial .anim_line_color{
    width: 25%;
    background-color: rgb(18, 18, 18);
}
.AnimationInitial .anim_line_color:nth-child(1){
    border-left: none;
}
.AnimationInitial .anim_line_3{
    display: flex;
    align-items: flex-end;
}

.AnimationInitial .anim_color_1{
    border-left: 1px solid rgba(77, 77, 77, .3);
}


.AnimationInitial .anim_color_2{
    border-left: 1px solid rgba(77, 77, 77, .3);

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    animation: anim_color_animation 1.5s linear;
    animation-fill-mode: forwards;    
}


.AnimationInitial .anim_color_3{
    width: 80px;

    border-left: 1px solid rgba(77, 77, 77, .3);

    overflow: hidden;    
    position: relative;
    
    animation: anim_color_animation 1.5s linear;
    animation-fill-mode: forwards;  
}


.AnimationInitial .anim_color_4{  
    border-left: 1px solid rgba(77, 77, 77, .3);
      
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    animation: anim_color_animation 1.5s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_color_animation {
    0%, 20% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}


.AnimationInitial .anim_color_3 .anim_show_percentage{
    position: relative;
    right: -10px;
    font-size: 22px;
}
.AnimationInitial .anim_color_3 .anim_show_percentage_animation{
    animation: anim_show_percentage 0.8s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_show_percentage {
    0% {
        right: -10px;
    }
    100% {
        right: 60px;
    }
}


.AnimationInitial .anim_hide_color_line{    
    animation: anim_hide_color_line 1s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_hide_color_line {
    0%{
        height: 100%;
    }
    100% {
        height: 0%;
    }
}