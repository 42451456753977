.ModelPage_Text{
    width: 100%;
    height: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;
    font-family: 'Archivo', sans-serif;

    animation-duration: 3s;
    animation-name: ModelPage_Text;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 150px 0px;
}
@keyframes ModelPage_Text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ModelPage_Text .div_text{
    width: 80%;
    height: 100%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModelPage_Text .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}
.ModelPage_Text .div_text .show_text .subtitle{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
}
.ModelPage_Text .div_text .show_text .title{
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    font-style: normal;
    margin-bottom: 0;
    text-transform: none;
    transition-delay: 0s;
    color: #ededed;
}
.ModelPage_Text .div_text .show_text .text{
    font-size: 18px;
    font-weight: 100;
}


.ModelPage_Text .list_video{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ModelPage_Text .list_video .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1024px) {
    .ModelPage_Text .div_text .show_text .title{
        font-size: 34px;
        line-height: initial;
    }
}
@media only screen and (max-width: 1000px) {
    .ModelPage_Text{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    .ModelPage_Text .list_video{
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .ModelPage_Text .list_video .show_video{
        height: auto;
        width: 90%;
    }
}
@media only screen and (max-width: 760px) {
    .ModelPage_Text .div_text .show_text{
        gap: 10px;
    }
    .ModelPage_Text .div_text .show_text .text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 510px) {
    .ModelPage_Text{
        gap: 30px;
    }
    .ModelPage_Text .div_text .show_text .title{
        line-height: 42px;
    }
    .ModelPage_Text .list_video .show_video{
        height: auto;
        width: 100%;
    }
}
