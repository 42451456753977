.ModelPage_Video{
    width: 80%;
    height: calc(100% - 300px);
    margin: 0% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: ModelPage_Video;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    padding: 150px 0px;
}
@keyframes ModelPage_Video {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.ModelPage_Video .div_text{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ModelPage_Video .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}
.ModelPage_Video .div_text .show_text .subtitle{
    font-size: 18px;
    font-weight: 100;
    text-transform: uppercase;
}
.ModelPage_Video .div_text .show_text .title{
    font-size: 30px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 0;
    text-transform: none;
    font-family: Montserrat;
}
.ModelPage_Video .div_text .show_text .text{
    font-size: 18px;
    font-weight: 100;
}


.ModelPage_Video .list_video{
    width: 100%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ModelPage_Video .list_video .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .ModelPage_Video{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    .ModelPage_Video .div_text{
        width: 100%;
        height: auto;
    }
    .ModelPage_Video .list_video{
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .ModelPage_Video .list_video .show_video{
        height: auto;
        width: 90%;
    }
}
@media only screen and (max-width: 760px) {
    .ModelPage_Video .div_text .show_text{
        gap: 10px;
    }
    .ModelPage_Video .div_text .show_text .text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 510px) {
    .ModelPage_Video{
        gap: 30px;
    }
    .ModelPage_Video .div_text .show_text .title{
        line-height: 38px;
    }
    .ModelPage_Video .list_video .show_video{
        height: auto;
        width: 100%;
    }
}