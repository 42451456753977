.Page_Contact{
    width: 80%;
    height: 80%;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Page_Contact;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 80px 0;
}
@keyframes Page_Contact {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.Page_Contact .div_text{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Contact .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}
.Page_Contact .div_text .show_text .subtitle{
    font-size: 18px;
    font-weight: 100;
    text-transform: uppercase;
}
.Page_Contact .div_text .show_text .title{
    font-weight: 600;
    font-style: normal;
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 0;
    text-transform: none;
    font-family: 'Archivo', sans-serif;
}
.Page_Contact .div_text .show_text .text{
    font-size: 18px;
    font-weight: 100;
}


.Page_Contact .list_form{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Page_Contact .list_form form{
    width: 80%;
}
.Page_Contact .list_form .div_form{
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: auto;
    background-color: rgb(86 86 86);
}
.Page_Contact .list_form .div_form .div_input .textarea{
    resize: none;
    height: 80px !important;
    min-height: 80px !important;
}
.Page_Contact .list_form .div_form .div_input .btn_save{
    width: 100%;
    border: transparent;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
    background-color: #7c9f06;
    color: #ffffff;
    cursor: pointer;
}
.Page_Contact .list_form .div_form .div_input .btn_save:hover {
    background-color: #5a7305;
    transform: scale(0.98);
}

.Page_Contact input, .Page_Contact select, .Page_Contact textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}

@media only screen and (max-width: 1000px) {
    .Page_Contact{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    .Page_Contact .div_text{
        width: 100%;
        height: auto;
    }
    .Page_Contact .list_form{
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .Page_Contact .list_form form{
        width: 100%;
    }
}
@media only screen and (max-width: 760px) {
    .Page_Contact .div_text .show_text{
        gap: 10px;
    }
    .Page_Contact .div_text .show_text .text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 510px) {
    .Page_Contact{
        gap: 30px;
    }
    .Page_Contact .list_video .show_video{
        height: auto;
        width: 100%;
    }
}
