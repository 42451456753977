.Page_Footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;

    background-color: #121212;
}

.Page_Footer .div_color_line{
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 180px;
}
.Page_Footer .div_color_line .line_top{
    width: 100%;
    height: 80px;
    min-height: 80px;
    display: flex;
    flex-direction: row;
}
.Page_Footer .div_color_line .line_top .line_ft_1{
    width: 25%;
    height: 100%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_top .line_ft_2{
    width: 25%;
    height: 60%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_top .line_ft_3{
    width: 25%;
    height: 40%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_top .line_ft_4{
    width: 25%;
    height: 60%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_bottom{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.Page_Footer .div_color_line .line_bottom .line_ft_1{
    width: 25%;
    height: 100%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_bottom .line_ft_2{
    width: 25%;
    height: 94%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_bottom .line_ft_3{
    width: 25%;
    height: 90%;
    background-color: #121212;
}
.Page_Footer .div_color_line .line_bottom .line_ft_4{
    width: 25%;
    height: 94%;
    background-color: #121212;
}

.Page_Footer .div_img{
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    background-color: #000000;
}
.Page_Footer .div_img .img{
    position: absolute;
    width: 70%;
    height: auto;
    left: 50%;
    transform: translate(-50%, -14%);
}

.Page_Footer .div_img .div_btn{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    bottom: 30px;
    z-index: 2;
}
.Page_Footer .div_img .div_btn .btn{  
    width: max-content;  
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #87C232;
    cursor: pointer;
}

.Page_Footer .div_data_footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 60px;
    width: 80%;
    height: 100%;
    flex-grow: 1;
    margin: 0% auto;
    position: absolute;
    z-index: 1;
}
.Page_Footer .div_data_footer .div_data_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
}
.Page_Footer .div_data_footer .div_data_rede_social .show_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.Page_Footer .show_data{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 1s;
}
.Page_Footer .show_data .div_btn{
    width: 46%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}   
.Page_Footer .show_data .div_btn .title{
    font-size: 31px;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: 16px;
    transition: 1s;
}
.Page_Footer .show_data .div_btn .btn{  
    width: max-content;  
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #87C232;
    cursor: pointer;
}
.Page_Footer .show_data .div_name_page .title_click{
    font-size: 14px;
    color: #707070;
}
.Page_Footer .show_data .div_name_page .name_page{
    color: #ffffff;
    font-size: 21px;
}

.Page_Footer .div_data_footer .div_data_rede_social .origin{
    text-align: left;
}
.Page_Footer .div_data_footer .div_data_rede_social .origin_center{
    text-align: center;
}

@media only screen and (max-width: 850px) {
    .Page_Footer .div_color_line .line_top .line_ft_1, .Page_Footer .div_color_line .line_top .line_ft_2{
        width: 50%;
    }
    .Page_Footer .div_color_line .line_bottom .line_ft_1, .Page_Footer .div_color_line .line_bottom .line_ft_2{
        width: 50%;
    }
    .Page_Footer .line_ft_3, .Page_Footer .line_ft_4{
        display: none !important;
    }
    .Page_Footer .div_img .img{
        transform: translate(-50%, 0%);
    }
}
@media only screen and (max-width: 680px) {
    .Page_Footer .div_color_line .line_bottom{
        height: calc(100% - 220px);
    }
    .Page_Footer .show_data{
        flex-direction: column;
        gap: 20px;
    }
    .Page_Footer .show_data .div_btn{
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .Page_Footer .show_data .div_btn .title{
        font-size: 24px;
    }
    .Page_Footer .show_data .div_name_page .name_page{
        font-size: 18px;
    }
}
@media only screen and (max-width: 670px) {
    .Page_Footer .div_color_line .line_top{
        height: 60px;
    }
    .Page_Footer .div_color_line .line_bottom{
        height: calc(100% - 170px);
    }
    .Page_Footer .div_data_footer{
        gap: 40px;
    }
    .Page_Footer .div_data_footer .div_data_rede_social{
        flex-direction: column;
        gap: 20px;
    }
}
@media only screen and (max-width: 580px) {
    .Page_Footer .div_color_line{
        gap: 80px;
    }
    .Page_Footer .div_img .img{
        width: 120%;
        transform: translate(-50%, 9%);
    }
    .Page_Footer .show_data .div_btn .title{
        font-size: 26px;
        line-height: 30px;
    }
    .Page_Footer .show_data .div_name_page .name_page{
        font-size: 12px;
    }
    .Page_Footer .div_data_footer .div_data_rede_social .origin, .Page_Footer .div_data_footer .div_data_rede_social .origin_center{
        text-align: center;
    }
    .Page_Footer .div_data_footer{
        gap: 20px;
    }
}